import React from 'react';
import { Blank } from 'grommet-icons';

const IconParticles = (props) => (
  <Blank viewBox="0 0 40 40" {...props}>
    <path d="M10.3685,13.2581A2.1813,2.1813,0,1,1,12.55,11.0767,2.1838,2.1838,0,0,1,10.3685,13.2581Zm0-2.9627a.7813.7813,0,1,0,.7813.7813A.782.782,0,0,0,10.3685,10.2954Zm19.263,25.658a2.1813,2.1813,0,1,1,2.1813-2.1813A2.1833,2.1833,0,0,1,29.6315,35.9534Zm0-2.9627a.7813.7813,0,1,0,.7813.7813A.7819.7819,0,0,0,29.6315,32.9907Zm-7.8429-3.1056A2.1813,2.1813,0,1,1,23.97,27.7038,2.1836,2.1836,0,0,1,21.7886,29.8851Zm0-2.9627a.7813.7813,0,1,0,.7813.7813A.7822.7822,0,0,0,21.7886,26.9224Zm7.8429,1.8156a2.181,2.181,0,1,1,2.1813-2.1807A2.1831,2.1831,0,0,1,29.6315,28.738Zm0-2.962a.781.781,0,1,0,.7813.7813A.7819.7819,0,0,0,29.6315,25.776Zm-16.2193.7813a2.1813,2.1813,0,1,1,2.1813-2.1813A2.1835,2.1835,0,0,1,13.4122,26.5574Zm0-2.9627a.7813.7813,0,1,0,.7813.7813A.7822.7822,0,0,0,13.4122,23.5947Zm11.1306.7813a2.1813,2.1813,0,1,1,2.1813-2.1813A2.1834,2.1834,0,0,1,24.5428,24.376Zm0-2.9627a.7813.7813,0,1,0,.7813.7813A.7825.7825,0,0,0,24.5428,21.4133Zm-8.84-1.5743a2.1812,2.1812,0,1,1,2.1813-2.181A2.1833,2.1833,0,0,1,15.7033,19.839Zm0-2.9624a.7813.7813,0,1,0,.7813.7813A.7819.7819,0,0,0,15.7033,16.8767Zm4.8238-2.1208a2.1812,2.1812,0,1,1,2.181-2.181A2.1838,2.1838,0,0,1,20.5271,14.7558Zm0-2.9624a.7812.7812,0,1,0,.781.7813A.7825.7825,0,0,0,20.5271,11.7935ZM16.8,8.409a2.1812,2.1812,0,1,1,2.1813-2.1813A2.1838,2.1838,0,0,1,16.8,8.409Zm0-2.9624a.7812.7812,0,1,0,.7813.781A.7819.7819,0,0,0,16.8,5.4466ZM28.2746,16.07a2.1813,2.1813,0,1,1,2.1813-2.1813A2.1836,2.1836,0,0,1,28.2746,16.07Zm0-2.9627a.7813.7813,0,1,0,.7813.7813A.7822.7822,0,0,0,28.2746,13.1073Z" />
  </Blank>
);

export default IconParticles;
